<template>
  <v-container class="main-container">
    <v-row align="center" justify="end">
      <v-col cols="12">
        <div class="text-right text-center">
          <v-divider class="rounded mb-10" color="primary" opacity="0.7" thickness="10" width="50%"></v-divider>
          <h1 class="text-primary">{{ $t('proj.title') }}</h1>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4" md="6">
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-carousel :show-arrows="false" class="rounded-lg" height="auto" hide-delimiters interval="6000">
              <v-carousel-item
                  contain
                  src="@/assets/project0.png"
              ></v-carousel-item>
              <v-carousel-item
                  contain
                  src="@/assets/project1.png"
              ></v-carousel-item>

              <v-carousel-item
                  contain
                  src="@/assets/project2.png"
              ></v-carousel-item>
              <v-carousel-item
                  contain
                  src="@/assets/project3.png"
              ></v-carousel-item>
              <v-carousel-item
                  contain
                  src="@/assets/project4.png"
              ></v-carousel-item>
              <v-carousel-item
                  contain
                  src="@/assets/project5.png"
              ></v-carousel-item>
            </v-carousel>
          </div>
          <h3 class="text-secondary">{{ $t('proj.1.title') }}</h3>
          <p v-html="$t('proj.1.desc')">
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue">
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/ts.png"></v-img>
              </v-avatar>
              TypeScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="red" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/angular.png"></v-img>
              </v-avatar>
              Angular
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="cyan" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/react.png"></v-img>
              </v-avatar>
              React
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="purple-lighten-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/bootstrap.svg"></v-img>
              </v-avatar>
              Bootstrap
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/mui.png"></v-img>
              </v-avatar>
              Material UI
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="white1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/material.png"></v-img>
              </v-avatar>
              Material Design
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(0)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-row>
              <v-col>
                <v-img contain src="@/assets/app1.jpg"></v-img>
              </v-col>
              <v-col>
                <v-img contain src="@/assets/app2.jpg"></v-img>
              </v-col>
              <v-col>
                <v-img contain src="@/assets/app3.jpg"></v-img>
              </v-col>
            </v-row>

          </div>
          <h3 class="text-primary">{{ $t('proj.4.title') }}</h3>
          <p v-html="$t('proj.4.desc')">
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="purple-lighten-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/kmm.png"></v-img>
              </v-avatar>
              Kotlin Multiplatform
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/kotlin.png"></v-img>
              </v-avatar>
              Kotlin
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/swift.png"></v-img>
              </v-avatar>
              Swift
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/compose.svg"></v-img>
              </v-avatar>
              Jetpack Compose
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="cyan-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/swiftui.png"></v-img>
              </v-avatar>
              SwiftUI
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/sqlite.png"></v-img>
              </v-avatar>
              SQLite
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(3)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
        <v-card v-if="!isLgAndUp" class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-img contain src="@/assets/uni1.png"></v-img>
          </div>
          <h3 class="text-primary">{{ $t('proj.6.title') }}</h3>
          <p>
            {{ $t('proj.6.desc') }}
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="cyan" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/react.png"></v-img>
              </v-avatar>
              React
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/mui.png"></v-img>
              </v-avatar>
              Material UI
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/java.svg"></v-img>
              </v-avatar>
              Java
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/oracle.png"></v-img>
              </v-avatar>
              Oracle SQL
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(5)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="6">
        <v-card v-if ="!isLgAndUp" class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-img contain src="@/assets/api.png"></v-img>
          </div>
          <h3 class="text-secondary">{{ $t('proj.3.title') }}</h3>
          <p>
            {{ $t('proj.3.desc') }}
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/ts.png"></v-img>
              </v-avatar>
              TypeScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="green-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/nodeJs.svg"></v-img>
              </v-avatar>
              NodeJS
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/sequelize.png"></v-img>
              </v-avatar>
              Sequelize
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="grey-darken-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/express.png"></v-img>
              </v-avatar>
              Express
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(2)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5 image-container">
            <v-img :src="awsImg" class="circle-image" contain></v-img>
          </div>
          <h3 class="text-secondary">{{ $t('proj.2.title') }}</h3>
          <p>
            {{ $t('proj.2.desc') }}</p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img :src="awsImg" class="fit-image"></v-img>
              </v-avatar>
              AWS
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-2" >
              <v-avatar class="pa-1 mr-1">
                <v-img class="fit-image" src="@/assets/EC2.png"></v-img>
              </v-avatar>
              EC2
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="red" >
              <v-avatar class="pa-1 mr-1">
                <v-img class="fit-image" src="@/assets/S3.png"></v-img>
              </v-avatar>
              S3
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="amber-darken-2" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/amplify.png"></v-img>
              </v-avatar>
              Amplify
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/lightsail.png"></v-img>
              </v-avatar>
              Lightsail
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="green" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/iotcore.svg"></v-img>
              </v-avatar>
              IoT Core
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="yellow-darken-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/ses.png"></v-img>
              </v-avatar>
              SES
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-2" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/lambda.png"></v-img>
              </v-avatar>
              Lambda
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(4)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-img contain src="@/assets/portfolio.png"></v-img>
          </div>
          <h3 class="text-primary">{{ $t('proj.5.title') }}</h3>
          <p>
            {{ $t('proj.5.desc') }}
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/ts.png"></v-img>
              </v-avatar>
              TypeScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="green-lighten-1" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/vue.png"></v-img>
              </v-avatar>
              Vue
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/vuetify.png"></v-img>
              </v-avatar>
              Vue
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(1)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
      </v-col>

      <v-col  v-if="isLgAndUp" cols="12" lg="4" md="6">
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-img contain src="@/assets/api.png"></v-img>
          </div>
          <h3 class="text-secondary">{{ $t('proj.3.title') }}</h3>
          <p>
            {{ $t('proj.3.desc') }}
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/ts.png"></v-img>
              </v-avatar>
              TypeScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="green-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/nodeJs.svg"></v-img>
              </v-avatar>
              NodeJS
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/sequelize.png"></v-img>
              </v-avatar>
              Sequelize
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="grey-darken-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/express.png"></v-img>
              </v-avatar>
              Express
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(2)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
        <v-card class="pa-5 mb-4 rounded-lg project-card" color="card">
          <div class="mb-5">
            <v-img contain src="@/assets/uni1.png"></v-img>
          </div>
          <h3 class="text-primary">{{ $t('proj.6.title') }}</h3>
          <p>
            {{ $t('proj.6.desc') }}
          </p>
          <v-row>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange-darken-3" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/js.png"></v-img>
              </v-avatar>
              JavaScript
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="cyan" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/react.png"></v-img>
              </v-avatar>
              React
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/mui.png"></v-img>
              </v-avatar>
              Material UI
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="orange" >
              <v-avatar class="pa-0 mr-2">
                <v-img class="fit-image" src="@/assets/java.svg"></v-img>
              </v-avatar>
              Java
            </v-chip>
            <v-chip class="mb-5 ml-2 mr-2 default-chip" color="blue-lighten-1" >
              <v-avatar class="pa-1 mr-2">
                <v-img class="fit-image" src="@/assets/oracle.png"></v-img>
              </v-avatar>
              Oracle SQL
            </v-chip>
          </v-row>
          <div class="overlay" @click="openModal(5)">
            <v-icon class="custom-icon">fa-solid fa-magnifying-glass</v-icon>
          </div>
        </v-card>
      </v-col>

    </v-row>
    <v-dialog v-model="dialogVisible" class= "w-100 w-md-75">
      <v-card v-if="currentProjectIndex === 0" class="pa-10 mb-4 rounded-lg" color="card">
        <v-row justify="center">
          <v-col cols="12">
            <div class= "mb-5 w-100 w-md-75" style="margin: auto;">
              <v-carousel class="rounded-lg" cycle hide-delimiters interval="6000" show-arrows="hover">
                <v-carousel-item contain src="@/assets/project0.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/project1.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/project2.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/project3.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/project4.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/project5.png"></v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>
        <h2 class="text-secondary">{{ $t('proj.1.title') }}</h2>
        <p>
          <strong>{{ $t('proj.desc') }}</strong><br>
          <span v-html="$t('proj.1.desc2')"></span>
          <br>
          <br>
          <strong>{{ $t('proj.feat') }}</strong><br>
          <br>
          <ul class="key-achievements" style="margin-left: 20px;">
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp1Title') }}</strong>
                <span>{{ $t('proj.1.bp1Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp2Title') }}</strong>
                <span>{{ $t('proj.1.bp2Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp3Title') }}</strong>
                <span>{{ $t('proj.1.bp3Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp4Title') }}</strong>
                <span>{{ $t('proj.1.bp4Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp5Title') }}</strong>
                <span>{{ $t('proj.1.bp5Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.1.bp6Title') }}</strong>
                <span>{{ $t('proj.1.bp6Desc') }}</span>
              </div>
            </li>
          </ul>
        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-3">
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/js.png"></v-img>
            </v-avatar>
            JavaScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue">
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/ts.png"></v-img>
            </v-avatar>
            TypeScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="red" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/angular.png"></v-img>
            </v-avatar>
            Angular
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="cyan" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/react.png"></v-img>
            </v-avatar>
            React
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="white1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/material.png"></v-img>
            </v-avatar>
            Material Design
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/mui.png"></v-img>
            </v-avatar>
            Material UI
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="purple-lighten-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/bootstrap.svg"></v-img>
            </v-avatar>
            Bootstrap
          </v-chip>
        </v-row>
      </v-card>
      <v-card v-if="currentProjectIndex === 1" class="pa-10 mb-4 rounded-lg" color="card">
        <v-row justify="center">
          <v-col cols="12">
           <div class= "mb-5 w-100 w-md-75" style="margin: auto;">
              <v-img contain src="@/assets/portfolio.png"></v-img>
            </div>
          </v-col>
        </v-row>
        <h3 class="text-primary">{{ $t('proj.5.title') }}</h3>
        <p>
          {{ $t('proj.5.desc') }}
        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-3" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/js.png"></v-img>
            </v-avatar>
            JavaScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/ts.png"></v-img>
            </v-avatar>
            TypeScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="green" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/vue.png"></v-img>
            </v-avatar>
            Vue
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/vuetify.png"></v-img>
            </v-avatar>
            Vue
          </v-chip>
        </v-row>
      </v-card>
      <v-card v-if="currentProjectIndex === 2" class="pa-10 mb-4 rounded-lg" color="card">
        <v-row justify="center">
          <v-col cols="12">
           <div class= "mb-5 w-100 w-md-75" style="margin: auto;">
              <v-img contain src="@/assets/api.png"></v-img>
            </div>
          </v-col>
        </v-row>
        <h3 class="text-primary">{{ $t('proj.3.title') }}</h3>
        <p>
          <strong>{{ $t('proj.desc') }}</strong>
          <span v-html="$t('proj.3.desc2')"></span>
          <strong>{{ $t('proj.keyFeat') }}</strong><br>
          <br>
          <ul class="key-achievements" style="margin-left: 20px;">
            <li>
              <strong class="text-blue-lighten-1">
                {{ $t('proj.3.bp1Title') }}</strong>
            </li>
            <br>
            <li>
              <strong class="text-blue-lighten-1">{{ $t('proj.3.bp2Title') }}</strong>
            </li>
            <br>
            <li>
              <strong class="text-blue-lighten-1">
                {{ $t('proj.3.bp3Title') }}</strong>
            </li>
            <br>
            <li>
              <strong class="text-blue-lighten-1">
                {{ $t('proj.3.bp4Title') }}</strong>
            </li>
            <br>
          </ul>

        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-3" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/js.png"></v-img>
            </v-avatar>
            JavaScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/ts.png"></v-img>
            </v-avatar>
            TypeScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="green-lighten-1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/nodeJs.svg"></v-img>
            </v-avatar>
            NodeJS
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue-lighten-1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/sequelize.png"></v-img>
            </v-avatar>
            Sequelize
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="white" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/express.png"></v-img>
            </v-avatar>
            Express
          </v-chip>
        </v-row>
      </v-card>
      <v-card v-if="currentProjectIndex === 3" class="pa-10 mb-4 rounded-lg" color="card">
        <v-row justify="center">
          <v-col cols="12">
            <v-row align="center" class="swiper-container mb-5" justify="center" @mouseleave="hover = false"
                   @mouseover="hover = true">
              <v-btn :class="['nav-button', { 'slide-in-left': hover }]" icon="fas fa-chevron-left" variant="elevated"
                     @click="swiperPrevSlide"></v-btn>
              <div class= "mb-5 w-100 w-md-75" style="margin: auto;">
                <swiper
                    ref="swiperRef"
                    :loop="true"
                    :modules="modules"
                    :slides-per-view="3"
                    :space-between="50"
                    autoplay
                    @swiper="onSwiper"
                >
                  <swiper-slide>
                    <v-img contain src="@/assets/app1.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app2.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app3.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app4.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app5.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app6.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app7.jpg"></v-img>
                  </swiper-slide>
                  <swiper-slide>
                    <v-img contain src="@/assets/app8.jpg"></v-img>
                  </swiper-slide>
                </swiper>
              </div>

              <v-btn :class="['nav-button', { 'slide-in-right': hover }]" icon="fas fa-chevron-right" variant="elevated"
                     @click="swiperNextSlide"></v-btn>
            </v-row>
          </v-col>
        </v-row>

        <h2 class="text-primary">{{ $t('proj.4.title') }}</h2>
        <p>
          <strong>{{ $t('proj.desc') }}</strong><br>
          <span v-html="$t('proj.4.desc2')"></span>
          <br>
          <br>
          <strong>{{ $t('proj.feat') }}</strong><br>
          <br>
          <ul class="key-achievements" style="margin-left: 20px;">
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp1Title') }}</strong>
                <span>{{ $t('proj.4.bp1Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp2Title') }}</strong>
                <span>{{ $t('proj.4.bp2Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp3Title') }}</strong>
                <span>{{ $t('proj.4.bp3Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp4Title') }}</strong>
                <span>{{ $t('proj.4.bp4Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp5Title') }}</strong>
                <span>{{ $t('proj.4.bp5Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.4.bp6Title') }}</strong>
                <span>{{ $t('proj.4.bp6Desc') }}</span>
              </div>
            </li>
          </ul>
        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="purple-lighten-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/kmm.png"></v-img>
            </v-avatar>
            Kotlin Multiplatform
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/kotlin.png"></v-img>
            </v-avatar>
            Kotlin
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/swift.png"></v-img>
            </v-avatar>
            Swift
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue-lighten-1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/compose.svg"></v-img>
            </v-avatar>
            Jetpack Compose
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="cyan-lighten-1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/swiftui.png"></v-img>
            </v-avatar>
            SwiftUI
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue-lighten-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/sqlite.png"></v-img>
            </v-avatar>
            SQLite
          </v-chip>
        </v-row>
      </v-card>
      <v-card v-if="currentProjectIndex === 4" class="pa-10 mb-4 rounded-lg" color="card">

        <v-row justify="center">
          <v-col cols="12">
            <div class="mb-5 image-container-small">
              <v-img :src="awsImg" class="circle-image" contain></v-img>
            </div>
          </v-col>
        </v-row>

        <h2 class="text-secondary">{{ $t('proj.2.title') }}</h2>
        <p>
          <strong>{{ $t('proj.desc') }}</strong><br>
          {{ $t('proj.2.desc') }}
          <br>
          <br>
          <strong>{{ $t('proj.feat') }}</strong><br>
          <br>
          <ul class="key-achievements" style="margin-left: 20px;">
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.2.bp1Title') }}</strong>
                <span>{{ $t('proj.2.bp1Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.2.bp2Title') }}</strong>
                <span>{{ $t('proj.2.bp2Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.2.bp3Title') }}</strong>
                <span>{{ $t('proj.2.bp3Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.2.bp4Title') }}</strong>
                <span>{{ $t('proj.2.bp4Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.2.bp5Title') }}</strong>
                <span>{{ $t('proj.2.bp5Desc') }}</span>
              </div>
            </li>
          </ul>
        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img :src="awsImg" class="fit-image"></v-img>
            </v-avatar>
            AWS
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-2" >
            <v-avatar class="pa-1 mr-1">
              <v-img class="fit-image" src="@/assets/EC2.png"></v-img>
            </v-avatar>
            EC2
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="red" >
            <v-avatar class="pa-1 mr-1">
              <v-img class="fit-image" src="@/assets/S3.png"></v-img>
            </v-avatar>
            S3
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="amber-darken-2" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/amplify.png"></v-img>
            </v-avatar>
            Amplify
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-3" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/lightsail.png"></v-img>
            </v-avatar>
            Lightsail
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="green" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/iotcore.svg"></v-img>
            </v-avatar>
            IoT Core
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="yellow-darken-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/ses.png"></v-img>
            </v-avatar>
            SES
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-2" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/lambda.png"></v-img>
            </v-avatar>
            Lambda
          </v-chip>
        </v-row>
      </v-card>
      <v-card v-if="currentProjectIndex === 5" class="pa-10 mb-4 rounded-lg" color="card">

        <v-row justify="center">
          <v-col cols="12">
           <div class= "mb-5 w-100 w-md-75" style="margin: auto;">
              <v-carousel class="rounded-lg" cycle hide-delimiters interval="6000" show-arrows="hover">
                <v-carousel-item contain src="@/assets/uni1.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/uni2.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/uni3.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/uni4.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/uni5.png"></v-carousel-item>
                <v-carousel-item contain src="@/assets/uni6.png"></v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>

        <h2 class="text-primary">{{ $t('proj.6.title') }}</h2>
        <p>
          <strong>{{ $t('proj.desc') }}</strong><br>
          {{ $t('proj.6.desc') }}

          <br>
          <br>
          <strong>{{ $t('proj.6.projectPhase') }}</strong><br>
          <br>
          <ol class="key-achievements-numbers" style="margin-left: 20px;">
            <strong>
              <li>{{ $t('proj.6.num1Title') }}</li>
            </strong>
            <ul class="key-achievements" style="margin-left: 20px;">
              <li>
                {{ $t('proj.6.num1Desc1') }}
              </li>
            </ul>

            <br>
            <strong>
              <li>{{ $t('proj.6.num2Title') }}</li>
            </strong>
            <ul class="key-achievements" style="margin-left: 20px;">
              <li>
                {{ $t('proj.6.num2Desc1') }}
              </li>
              <li>
                {{ $t('proj.6.num2Desc2') }}
              </li>
              <li>
                {{ $t('proj.6.num2Desc3') }}
              </li>
            </ul>

            <br>
            <strong>
              <li>{{ $t('proj.6.num3Title') }}</li>
            </strong>
            <ul class="key-achievements" style="margin-left: 20px;">
              <li>
                {{ $t('proj.6.num3Desc1') }}
              </li>
              <li>
                {{ $t('proj.6.num3Desc2') }}
              </li>
              <li>
                {{ $t('proj.6.num3Desc3') }}
              </li>
            </ul>
          </ol>

          <br>
          <br>
          <strong>{{ $t('proj.feat') }}</strong><br>
          <br>
          <ul class="key-achievements" style="margin-left: 20px;">
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.6.bp1Title') }}</strong>
                <span>{{ $t('proj.6.bp1Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.6.bp2Title') }}</strong>
                <span>{{ $t('proj.6.bp2Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.6.bp3Title') }}</strong>
                <span>{{ $t('proj.6.bp3Desc') }}</span>
              </div>
            </li>
            <br>
            <li>
              <div class="achievement-item">
                <strong class="text-blue-lighten-1">{{ $t('proj.6.bp4Title') }}</strong>
                <span>{{ $t('proj.6.bp4Desc') }}</span>
              </div>
            </li>
          </ul>
        </p>
        <v-row>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange-darken-3" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/js.png"></v-img>
            </v-avatar>
            JavaScript
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="cyan" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/react.png"></v-img>
            </v-avatar>
            React
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/mui.png"></v-img>
            </v-avatar>
            Material UI
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="orange" >
            <v-avatar class="pa-0 mr-2">
              <v-img class="fit-image" src="@/assets/java.svg"></v-img>
            </v-avatar>
            Java
          </v-chip>
          <v-chip class="mb-5 ml-2 mr-2 zoom-chip" color="blue-lighten-1" >
            <v-avatar class="pa-1 mr-2">
              <v-img class="fit-image" src="@/assets/oracle.png"></v-img>
            </v-avatar>
            Oracle SQL
          </v-chip>
        </v-row>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, Ref, ref, watch} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import type {Swiper as SwiperType} from 'swiper';
import {useTheme} from "vuetify";
import {useI18n} from "vue-i18n";
import { useDisplay } from 'vuetify'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  name: 'ProjectsPage',

  setup() {
    const dialogVisible = ref(false);
    const currentProjectIndex = ref<number | null>(null);
    const hover = ref(false);
    const { lgAndUp } = useDisplay()

    const openModal = (index: number) => {
      currentProjectIndex.value = index;
      dialogVisible.value = true;
    };

    const swiperInstance = ref()

    function onSwiper(swiper: SwiperType) {
      swiperInstance.value = swiper
    }

    const swiperNextSlide = () => {
      swiperInstance.value.slideNext()
    };
    const swiperPrevSlide = () => {
      swiperInstance.value.slidePrev()
    };

    const theme = useTheme();

    const isDark = computed(() => theme.global.current.value.dark);

    const awsImg = computed(() => {
      return isDark.value ? require('@/assets/aws.svg') : require('@/assets/aws.png');
    });

    return {
      dialogVisible,
      currentProjectIndex,
      openModal,
      hover,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      images: [
        '@/assets/app1.jpg',
        '@/assets/app2.jpg',
        '@/assets/app3.jpg',
        '@/assets/app4.jpg',
        '@/assets/app5.jpg',
        '@/assets/app6.jpg',
        '@/assets/app7.jpg',
        '@/assets/app8.jpg',
      ],
      swiperPrevSlide,
      swiperNextSlide,
      onSwiper,
      awsImg,
      isLgAndUp: lgAndUp,
    };
  },
});
</script>

<style scoped>
.text-right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

p {
  margin-bottom: 45px;
  line-height: 1.8;
}


.fit-image {
  object-fit: contain;
  width: auto;
  height: auto;
}

.project-card {
  position: relative;
  overflow: hidden;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(var(--v-theme-text), 0.4);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.project-card:hover .overlay {
  opacity: 1;
}

.custom-icon {
  font-size: 500%;
}

.key-achievements {
  list-style-type: disc;
  padding-left: 1em;
}

.key-achievements-numbers {
  list-style-type: decimal;
  padding-left: 1em;
}

.key-achievements li {
  display: list-item;
}

.achievement-item {
  display: flex;
  align-items: flex-start;
}

.achievement-item strong {
  width: 25%;
  flex-shrink: 0;
}

.achievement-item span {
  flex-grow: 1;
  padding-left: 10px;
}

.swiper-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.nav-button {
  transition: all 0.3s ease;
  opacity: 0;
}

.slide-in-left {
  left: -50px; /* Start further on the left side */
  animation: slideInLeft 0.3s forwards;
}

.slide-in-right {
  right: -50px; /* Start further on the right side */
  animation: slideInRight 0.3s forwards;
}

.swiper-container:hover .nav-button {
  opacity: 1;
}


@keyframes slideInLeft {
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideInRight {
  to {
    right: 0;
    opacity: 1;
  }
}

.image-container {
  width: 65%;
  aspect-ratio: 1;
  background-color: rgba(255, 195, 0, 0.35);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}

.image-container-small {
  width: 20%;
  aspect-ratio: 1;
  background-color: rgba(255, 195, 0, 0.35);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}

.circle-image {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
}


.text-center {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
}

@media (max-width: 960px) {
  h1 {
    font-size: 7vw !important;
    margin: 0;

  }

  h2 {
    font-size: 4vw !important;
    margin: 0;
  }

  .text-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .default-chip, .zoom-chip {
    font-size: 0.9rem !important;
    height: 30px !important;
  }

  .image-container-small {
    width: 30%;
    aspect-ratio: 1;
    background-color: rgba(255, 195, 0, 0.35);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
  }
  .achievement-item strong {
    width: 30%;
    flex-shrink: 0;
  }

}

@media (max-width: 600px) {
  h2 {
    font-size: 6vw !important;
    margin: 0;
  }
  h1 {
    font-size: 10vw !important;
  }

  .default-chip, .zoom-chip  {
    font-size: 0.9rem !important;
    height: 30px !important;
  }

  .image-container-small {
    width: 40%;
    aspect-ratio: 1;
    background-color: rgba(255, 195, 0, 0.35);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
  }
}

@media (max-width: 400px) {
  h2 {
    font-size: 10vw !important;
    margin: 0;
  }
  .image-container-small {
    width: 70%;
    aspect-ratio: 1;
    background-color: rgba(255, 195, 0, 0.35);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
  }
  h1 {
    font-size: 10vw !important;
  }

  .default-chip, .zoom-chip  {
    font-size: 0.6rem !important;
    height: 20px !important;
    padding: 5px !important;
  }


}

.default-chip {
  font-size: 1rem;
  height: 38px;
}

.zoom-chip {
  font-size: 1.125rem;
  height: 44px;
}




</style>