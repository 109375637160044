<template>
  <v-container class="main-container">
    <v-row align="center" justify="end">
      <v-col cols="12">
        <div class="text-right">
          <v-divider thickness="10" width="50%" color="primary" opacity="0.7" class="rounded mb-10"></v-divider>
          <h1 class="text-primary">{{ $t('skillsPage.title') }}</h1>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <!-- First Column -->
      <v-col cols="12" md="4" class="mb-4">
        <v-card class="pa-4"  color="card">
          <h2>Backend</h2>
          <v-divider class="mb-3"></v-divider>
          <v-row align="start" justify="start" class="ma-2">
            <v-col cols="4" lg="3" class="text-center  custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/js.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >JavaScript</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/ts.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >TypeScript</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/nodeJs.svg" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >NodeJS</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/express.webp" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >ExpressJS</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/sequelize.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Sequelize</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/laravel.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Laravel</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/graphQL.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >GraphQL</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/spring.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Spring</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-4 mt-4"  color="card">
          <h2>Frontend</h2>
          <v-divider class="mb-3"></v-divider>
          <v-row align="start" justify="start" class="ma-2">
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/html5.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >HTML5</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/css.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >CSS</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/sass.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >SASS/SCSS</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/react.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >React</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/angular.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Angular</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/vue.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >VueJS</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/bootstrap.svg" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Bootstrap</v-tooltip>
              </div>
            </v-col>
            <v-col cols="4" lg="3"  class="text-center custom-col-big">
              <div class="icon-container">
                <v-img src="@/assets/material.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Material Design</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Second Column -->
      <v-col cols="12" md="8" class="mb-4">
        <v-card class="pa-4 mb-4"  color="card">
          <h2>{{ $t('skillsPage.devOps') }}</h2>
          <v-divider class="mb-3"></v-divider>
          <v-row align="start" justify="space-between" class="ma-2">
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/git.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Git</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/github.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Github</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/gitlab.svg" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Gitlab</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/bitbucket.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Bitbucket</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/aws.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >AWS</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/docker.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Docker</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/heroku.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Heroku</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/firebase.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Firebase</v-tooltip>
              </div>
            </v-col>

            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/ga.webp" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Google Analytics</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/postman.svg" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Postman</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/apache.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Apache</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/gradle.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >Gradle</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/npm.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >NPM</v-tooltip>
              </div>
            </v-col>

            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/S3.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >AWS S3</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/EC2.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >AWS EC2</v-tooltip>
              </div>
            </v-col>
            <v-col class="text-center custom-col">
              <div class="icon-container">
                <v-img src="@/assets/amplify.png" contain></v-img>
                <v-tooltip
                    activator="parent"
                    location="bottom"
                >AWS Amplify</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col class="custom-card-col mb-4">
            <v-card class="pa-0" color="card">
              <h2 class="pt-4 pl-4">Mobile</h2>
              <v-divider class="mb-3"></v-divider>
              <v-row align="start" justify="start" class="ma-2">
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/kotlin.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Kotlin</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/kmm.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Kotlin Mutliplatform</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/compose.svg" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Jetpack Compose</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/swift.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Swift</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/swiftui.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >SwiftUI</v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="custom-card-col mb-4">
            <v-card class="pa-0" color="card">
              <h2 class="pt-4 pl-4">Data</h2>
              <v-divider class="mb-3"></v-divider>
              <v-row align="start" justify="start" class="ma-2">
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container-small">
                    <v-img src="@/assets/mysql.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >MySQL</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/sqlite.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >SQLite</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/mariadb.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >MariaDB</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/rds.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >AWS RDS</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/dynamo.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >AWS DynamoDB</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="4" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/postgresql.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >PostgreSQL</v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="custom-card-col-small mb-4">
            <v-card class="pa-0" color="card">
              <h2 class="pt-4 pl-4">IOT</h2>
              <v-divider class="mb-3"></v-divider>
              <v-row align="start" justify="start" class="ma-2">
                <v-col cols="2" lg= "6" xl="6"  class="text-center custom-icon-small">
                  <div class="icon-container-medium">
                    <v-img src="@/assets/arduino.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Arduino</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="2" lg= "6" xl="6"  class="text-center custom-icon-small">
                  <div class="icon-container-medium">
                    <v-img src="@/assets/rpi.svg" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >Raspberry PI</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="2" lg= "6" xl="6"  class="text-center custom-icon-small">
                  <div class="icon-container-medium">
                    <v-img src="@/assets/espressif.png" contain></v-img>
                    <v-tooltip
                        activator="parent"
                        location="bottom"
                    >ESP32 - Espressif</v-tooltip>
                  </div>
                </v-col>
                <v-col cols="2" lg= "6" xl="6" class="text-center custom-icon-small">
                  <div class="icon-container">
                    <v-img src="@/assets/iotcore.svg" contain></v-img>
                  </div>
                  <v-tooltip
                      activator="parent"
                      location="bottom"
                  >AWS IOT Core</v-tooltip>
                </v-col>
                <v-col cols="2" lg= "6" xl="6"  class="text-center  custom-icon-small">
                  <div class="icon-container-medium">
                    <v-img src="@/assets/c.png" contain></v-img>
                  </div>
                  <v-tooltip
                      activator="parent"
                      location="bottom"
                  >C</v-tooltip>
                </v-col>
                <v-col cols="2" lg= "6" xl="6"  class="text-center  custom-icon-small">
                  <div class="icon-container-medium">
                    <v-img src="@/assets/c++.png" contain></v-img>
                  </div>
                  <v-tooltip
                      activator="parent"
                      location="bottom"
                  >C++</v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </v-col>


    </v-row>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SkillsPage',
});
</script>

<style scoped>
.text-right {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.icon-container {
  border-radius: 50%;
  border: 4px solid rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-icon-background));
  padding: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}

.icon-container:hover, .icon-container-small:hover, .icon-container-medium:hover {
  border: 4px solid rgb(var(--v-theme-primary-lighten-1));
  background-color: rgba(var(--v-theme-icon-background-hover));
  box-shadow: 0 4px 10px rgb(var(--v-theme-primary), 0.8);
  cursor: pointer;
}


.icon-container-small {
  border-radius: 50%;
  border: 4px solid rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-icon-background));
  padding: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}

.icon-container-medium {
  border-radius: 50%;
  border: 4px solid rgb(var(--v-theme-primary));
  background-color: rgb(var(--v-theme-icon-background));
  padding: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}


.custom-col {
  flex: 0 0 11.3%;
  max-width: 11.3%;
  padding: 12px;
}

.custom-card-col {
  flex: 0 0 37%;
  max-width: 37%;
}

.custom-card-col-small {
  flex: 0 0 26%;
  max-width: 26%;
}

@media (max-width: 1264px) {
  .custom-col {
    flex: 0 0 15.3%;
    max-width: 15.3%;
    padding: 12px;
  }

  .custom-card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .custom-card-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }


}

@media (max-width: 960px) {
  .custom-card-col,
  .custom-card-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-col, .custom-icon-small, .custom-col-big {
    flex: 0 0 18%;
    max-width: 18%;
    padding: 12px;
  }




  h1 {
    font-size: 7vw !important;
    margin: 0;

  }
  .text-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .text-right {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }

}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

@media (max-width: 600px) {

  h1 {
    font-size: 10vw !important;
  }


}

@media (max-width: 500px) {
  .custom-card-col,
  .custom-card-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-col, .custom-icon-small, .custom-col-big  {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 6px;
  }

  .icon-container {
    border-radius: 50%;
    border: 2px solid rgb(var(--v-theme-primary));
    background-color: rgb(var(--v-theme-icon-background));
    padding: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
  }

  .icon-container:hover, .icon-container-small:hover, .icon-container-medium:hover {
    border: 2px solid rgb(var(--v-theme-primary-lighten-1));
    background-color: rgba(var(--v-theme-icon-background-hover));
    box-shadow: 0 4px 10px rgb(var(--v-theme-primary), 0.8);
    cursor: pointer;
  }


  .icon-container-small {
    border-radius: 50%;
    border: 2px solid rgb(var(--v-theme-primary));
    background-color: rgb(var(--v-theme-icon-background));
    padding: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
  }

  .icon-container-medium {
    border-radius: 50%;
    border: 2px solid rgb(var(--v-theme-primary));
    background-color: rgb(var(--v-theme-icon-background));
    padding: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
  }
}


</style>