// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {createVuetify, ThemeDefinition} from 'vuetify'
import {aliases, fa} from "vuetify/iconsets/fa";

const lightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#fdfdfd',
        'icon-background': '#ffffff',
        'icon-background-hover': '#eafbd4',
        surface: '#F1F8F6',
        primary: '#388E3C',           // Main green color
        'primary-darken-1': '#1B5E20', // Darker green
        'primary-lighten-1': '#78ef86', // Lighter green
        secondary: '#4CAF50',          // Lighter green
        'secondary-darken-1': '#2E7D32', // Darker lighter green
        'secondary-darken-5': '#139f49', // Darker lighter green
        text: '#2c2c2c',
        'text-secondary': '#c5c5c5',
        card: '#e9ebed',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
}

const darkTheme: ThemeDefinition = {
    dark: true,
    colors: {
        background: '#121212',
        'icon-background': '#efefef',
        'icon-background-hover': '#baf6ac',
        surface: '#1E1E1E',
        primary: '#57ec5c',          // Main green color
        'primary-darken-1': '#57bc60', // Darker green
        'primary-lighten-1': '#00ff0d', // Lighter green
        secondary: '#1fcf65',          // Lighter green
        'secondary-darken-1': '#149146', // Darker lighter green
        'secondary-darken-5': '#023c18', // Darker lighter green
        text: '#ffffff',
        'text-secondary': '#dfdfdf',
        card: '#303030',
        error: '#CF6679',
        info: '#64B5F6',
        success: '#81C784',
        warning: '#FFB74D',
    },
}

export default createVuetify({
        icons: {
            defaultSet: 'fa',
            aliases,
            sets: {
                fa,
            },
        },
        theme: {
            defaultTheme : 'lightTheme',
            themes: {
                lightTheme,
                darkTheme
            }
        },
    },
)
