<template>
  <v-container class="ml-10 mr-10">
    <v-row align="start" justify="start">

      <v-col cols="12" class="text-center">
        <v-divider class="rounded mb-10" color="primary" opacity="0.7" thickness="10" width="50%"></v-divider>

        <h1 class="text-primary mb-10">{{ $t('workPage.title') }}</h1>
        <v-card class="pa-md-10 ma-md-10 my-10 mx-0 pa-5" color="card">
          <v-row>
            <v-col cols="12" xl="2" md="4">
              <v-img contain src="@/assets/otg.svg" class="mx-auto w-50 w-md-100"></v-img>
            </v-col>
            <v-col cols="12" xl="10" md="8">
              <h2 class="text-text mb-5">{{ $t('workPage.otg.title') }}</h2>
              <h4 class="text-text mb-10">{{ $t('workPage.otg.duration') }}</h4>
              <p class="text-text work-desc" v-html="$t('workPage.otg.description')">
              </p>
              <v-expand-transition>
                <div v-if="showMore" class="work-desc  mt-10">
                  <p><strong>{{ $t('workPage.keyAchiev') }}</strong></p><br>
                  <ul class="key-achievements" style="margin-left: 20px;">
                    <li>
                      <div class="achievement-item">
                        <strong class="text-blue-lighten-1">{{ $t('workPage.otg.bp1Title') }}</strong>
                        <span>{{ $t('workPage.otg.bp1Desc') }}</span>
                      </div>
                    </li>
                    <br>
                    <li>
                      <div class="achievement-item">
                        <strong class="text-blue-lighten-1">{{ $t('workPage.otg.bp2Title') }}</strong>
                        <span>{{ $t('workPage.otg.bp2Desc') }}</span>
                      </div>
                    </li>
                    <br>
                    <li>
                      <div class="achievement-item">
                        <strong class="text-blue-lighten-1">{{ $t('workPage.otg.bp3Title') }}</strong>
                        <span>{{ $t('workPage.otg.bp3Desc') }}</span>
                      </div>
                    </li>
                    <br>
                    <li>
                      <div class="achievement-item">
                        <strong class="text-blue-lighten-1">{{ $t('workPage.otg.bp4Title') }}</strong>
                        <span>{{ $t('workPage.otg.bp4Desc') }}</span>
                      </div>
                    </li>
                  </ul>
                  <br><br>
                  <p>{{ $t('workPage.otg.conclusion') }}</p>
                </div>
              </v-expand-transition>
              <p class="read-more-text mt-10" @click="showMore = !showMore">
                {{ showMore ? $t('readLess') : $t('readMore')  }}
                <v-icon :class="{ 'rotate-icon': showMore }" class="ml-3 small-icon">fa-solid fa-chevron-down</v-icon>
              </p>

            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-md-10 ma-md-10 my-10 pa-5" color="card">

          <v-row>
            <v-col cols="12" xl="2" md="4">
              <v-img contain src="@/assets/tessi.webp" class="mx-auto w-66 w-md-100"></v-img>
            </v-col>
            <v-col cols="12" xl="10" md="8">
              <h2 class="text-text mb-5">{{ $t('workPage.tessi.title') }}</h2>
              <h4 class="text-text mb-10">{{ $t('workPage.tessi.duration') }}</h4>
              <p class="text-text work-desc">
                {{ $t('workPage.tessi.description') }}
              </p>

            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';


export default defineComponent({
  name: 'WorkExperiencePage',
  data() {
    return {
      showMore: false,
    };
  },
});

</script>

<style scoped>
h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

strong {
  font-weight: bold;
}


.work-desc {
  font-size: 1.2em;
  line-height: 1.6;
}

.read-more-text {
  cursor: pointer;
  color: #1976d2; /* Customize this color as needed */
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.v-icon {
  transition: transform 0.3s;
}

.rotate-icon {
  transform: rotate(180deg);
}

.small-icon {
  font-size: 100%;
}

.key-achievements {
  list-style-type: disc;
  padding-left: 1em;
}

.key-achievements li {
  margin-bottom: 10px;
  display: list-item;
}

.achievement-item {
  display: flex;
  align-items: flex-start;
}

.achievement-item strong {
  width: 25%;
  flex-shrink: 0;
}

.achievement-item span {
  flex-grow: 1;
  padding-left: 10px;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: start !important;
}


@media (max-width: 960px) {
  h1 {
    font-size: 7vw !important;
    margin: 0;

  }
  h2 {
    font-size: 3vw !important;
    margin: 0;

  }

  .text-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .achievement-item strong {
    width: 30%;
    flex-shrink: 0;
  }

}

@media (max-width: 600px) {

  h1 {
    font-size: 10vw !important;
  }

  h2 {
    font-size: 4vw !important;
    margin: 0;

  }
}


</style>