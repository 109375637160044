<template>
  <v-container class="ml-10 mr-10" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8" md="9" class="left-column d-md-flex flex-column align-center mb-10">
        <h1>{{ $t('hi') }}</h1>
        <h2 id="job-title" class="text-info"></h2>
        <v-row class="social-icons" justify="center">
          <v-col cols="auto">
                <a href="mailto:mathis@dufrasnes.fr" target="_blank">
                  <v-icon icon="fas fa-envelope" color="secondary"></v-icon>
                  <v-tooltip
                      activator="parent"
                      location="bottom"
                  >{{ $t('mail') }}</v-tooltip>
                </a>
          </v-col>
          <v-col cols="auto">
            <a  href="https://www.linkedin.com/in/mathis-dufrasnes/" target="_blank">
              <v-icon icon="fab fa-linkedin" color="secondary"></v-icon>
              <v-tooltip
                  activator="parent"
                  content-class="custom-tooltip"
                  location="bottom"
              >LinkedIn</v-tooltip>
            </a>
          </v-col>
          <v-col cols="auto">
            <a href="https://github.com/mat-duf" target="_blank">
              <v-icon icon="fab fa-github" color="secondary"></v-icon>
              <v-tooltip
                  activator="parent"
                  location="bottom"
              >GitHub</v-tooltip>
            </a>
          </v-col>
          <v-col cols="auto">
            <v-icon icon="fab fa-discord" color="secondary" @click="copyDiscordToClipboard"></v-icon>
            <v-tooltip
                activator="parent"
                location="bottom"
            >Discord<br> {{ $t('clickToCopy') }}</v-tooltip>
            <v-snackbar v-model="snackbar" :timeout="2000" color="success">
              {{ snackbarText }}
            </v-snackbar>
          </v-col>
        </v-row>
        <p v-html="$t('description.part1')"></p>
        <p v-html="$t('description.part2')"></p>
        <a :href="resumeLink" target="_blank">
          <v-btn color="primary" class="mt-5 resume-button">
            <v-row align="center" no-gutters>
              <span class="mr-3">{{ $t('myResume') }}</span>
              <v-icon icon="fas fa-download" class="ml-3"></v-icon>
            </v-row>
          </v-btn>
        </a>
      </v-col>
      <v-col  cols="12"
              lg="4" md="3"
              class="right-column d-md-flex flex-column align-center">
        <div class="profile-container">
          <img class="profile-image rounded-circle border-secondary border-xl border-opacity-100"
               :src="require('@/assets/photo_mathis6.png')">
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import TypeIt from "typeit";
import {useI18n} from "vue-i18n";


function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  } as T;
}



export default defineComponent({
  name: 'HomePage',
  data() {
    return {
      snackbar: false,
      snackbarText: '',
    };
  },
  setup() {
    const { locale } = useI18n();
    const currentLanguage = computed(() => locale.value);

    const resumeLink = computed(() => {
      return currentLanguage.value === 'en' ? '/CV_EN.pdf' : '/CV_FR.pdf';
    });

    return {
      currentLanguage,
      resumeLink,
      typeitInstance: null as TypeIt | null,
    };
  },
  methods: {
    openEmail() {
      window.location.href = 'mailto:mathis@dufrasnes.fr';
    },
    openLinkedIn() {
      window.open('https://linkedin.com/in/mathis-dufrasnes', '_blank');
    },
    openGitHub() {
      window.open('https://github.com/mat-duf', '_blank');
    },
    copyDiscordToClipboard() {
      const discordID = 'mathisduf';
      navigator.clipboard.writeText(discordID).then(() => {
        this.snackbarText = this.$t('usernameCopied');
        this.snackbar = true;
      });
    },

    initTypeIt() {
      this.typeitInstance = new TypeIt("#job-title", {
        speed: 100,
        lifeLike: true,
        waitUntilVisible: true,
        loop: true,
      })
          .type(this.$t("fsDev"))
          .pause(2000)
          .delete()
          .type(this.$t("backendDev"))
          .pause(2000)
          .delete()
          .type(this.$t("frontendDev"))
          .pause(2000)
          .delete()
          .type(this.$t("mobDev"))
          .pause(2000)
          .delete()
          .go();
    },
    updateTypeIt() {
      this.typeitInstance?.reset(undefined);
      this.initTypeIt();
    },
  },
  mounted() {
    this.initTypeIt();
    const debouncedUpdateTypeIt = debounce(this.updateTypeIt, 300);
    this.$watch(
        "$i18n.locale",
        () => {
          debouncedUpdateTypeIt();
        });
  },
});

</script>

<style scoped lang="scss">
.left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.profile-container {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  animation: pulsate 2s ease-out infinite;
}

.profile-image {
  width: 100%;
  height: auto;
  border-radius: 50%;
  background: rgb(var(--v-theme-text-secondary), 0.5);
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(26, 216, 91, 0.4);
  }
  70% {
    box-shadow: 0 0 0 40px rgba(26, 216, 91, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(72, 133, 237, 0);
  }
}

.social-icons {
  margin: 20px 0;
}

.icon {
  width: 30px;
  height: 30px;
}

h1 {
  font-size: 5em;
  margin: 0;
}

p {
  margin: 20px 0;
  font-size: 1.5em;
  line-height: 1.5;
}

@media (max-width: 960px) {
  h1 {
    font-size: 10vw !important;
    margin: 0;
  }
  p {
    margin: 20px 0;
    font-size: 3vw !important;
    line-height: 1.5;
    text-align: center;
  }

  .left-column {
    padding-left: 5%;
    padding-right: 5%;
  }

  .right-column {
    padding-left: 15%;
    padding-right: 15%;
  }

  h2 {
    font-size: 7vw !important;
  }
}

@media (max-width: 600px) {

  p {
    font-size: 4vw !important;
  }

}


h2 {
  font-size: 3em;
}


</style>