// i18n.ts
import {createI18n} from 'vue-i18n';

const messages = {
    en: {
        home: 'Home',
        aboutMeTitle: 'About Me',
        skills: 'Skills',
        work: 'Work Experience',
        projects: 'Projects',
        contact: 'Contact',
        mail: 'Mail',
        myResume: 'My Resume',
        clickToCopy: 'Click to copy',
        usernameCopied: 'Username copied to clipboard',
        hi: 'Hi, I am Mathis',
        fsDev: 'Full Stack Developer',
        backendDev: 'Backend Developer',
        frontendDev: 'Frontend Developer',
        mobDev: 'Mobile Developer',
        description: {
            part1: 'Passionate about <span class="text-info font-weight-bold">technology</span> and <span class="text-secondary font-weight-bold">sustainability</span> 🌱, I worked as CIO and lead developer for almost 3 years in a startup.',
            part2: 'Now looking to leverage my skills in a company actively involved in protecting the <span class="text-secondary font-weight-bold">environment</span> 🌍.'
        },

        aboutMe: {
            title: 'About Me',
            description: 'Graduate from a Dual Masters Degree in IT from <span class="text-info"\n' +
                '                                                               style="font-weight: bold"> ETS Montréal</span> and <span\n' +
                '            class="text-info" style="font-weight: bold">Junia HEI</span> (general engineering school in France).\n' +
                '          <br>\n' +
                '          I am a self-taught autodidact, and I thrive in both independent and team environments.<br>\n' +
                '          Known for my adaptability and versatility, I love to take on new challenges.',
            interests:'<strong class="text-secondary">Interests : </strong> Muay Thai, Guitar, Reading, Video\n' +
                '          Games',
            native: 'Native',
            bilingual: 'Bilingual',
            conversational: 'Conversational',
            fullStackExp: '<span class="number text-primary">5 Years</span> <span class="label">Full Stack Experience</span>',
            mobileExp: '<span class="number text-primary">2 Years</span> <span class="label">Mobile Experience</span>',
            projectsCompleted: '<span class="number text-primary">10+</span> <span class="label">Completed Projects</span>'
        },
        skillsPage: {
            title: 'Skills',
            devOps: 'DevOps, Cloud Services & Version Control',
        },
        readMore:'Read More',
        readLess:'Read Less',
        workPage: {
            title: 'Work Experience',
            keyAchiev: 'Key Achievements:',
            otg: {
                title:'Lead Developer at Off The Grid',
                duration:'Sept 2021 - July 2024 (~ 3 years)',
                description: 'As <strong class="text-blue-lighten-1">Lead Developer</strong> & <strong class="text-blue-lighten-1">Chief\n' +
                    '                Information Officer</strong> for almost 3 years, I was in charge of conceiving,\n' +
                    '                developing and maintaining the entire IT infrastructure for a smart IOT spinning bike.<br><br>\n' +
                    '                I leveraged my <strong class="text-blue-lighten-1">full stack development</strong> skills and <strong\n' +
                    '                  class="text-blue-lighten-1">extensive experience with\n' +
                    '                AWS</strong> to create a robust and\n' +
                    '                interconnected infrastructure. I also developed a\n' +
                    '                <strong class="text-blue-lighten-1">mobile app</strong> for users to access their data\n' +
                    '                seamlessly.<br><br>\n' +
                    '                I handled all other IT needs of the startup including IoT development, domain management, cybersecurity\n' +
                    '                &\n' +
                    '                email deliverability.',
                bp1Title:'Back-End Development:',
                bp1Desc:'Developed a secure, robust, and scalable API with NodeJS, ExpressJS, and Sequelize, managing database interactions and serving the web app, mobile app, and IoT services for workout session storage.',
                bp2Title:'Front-End Development:',
                bp2Desc:'Designed a web app for clients to access their fleet and view data analysis on training\n' +
                    '                          sessions, monthly usage, and progress. Included an admin interface for managing clients, monitoring the fleet, and tracking data trends and issues.',
                bp3Title:'Mobile App Development:',
                bp3Desc:'Developed a gamified mobile app for users to track their workout data, enhancing engagement and experience through eco-friendly challenges.',
                bp4Title:'AWS Integration:',
                bp4Desc:'Utilized extensive AWS knowledge to build a scalable and reliable ecosystem, deploying each resource with CI/CD practices.',
                conclusion:'My work at Off The Grid highlights my capabilities as a full-stack developer, emphasizing efficient\n' +
                    '                    infrastructure design and seamless integration of technological components.'
            },
            tessi: {
                title:'Automation of Software Tests at Tessi',
                duration:'June - July 2019 (2 months)',
                description: 'Conceived, prepared, and executed the testing phase for a major application update, utilizing SquashTM.\n' +
                    'Collaborated with the development team to identify and resolve issues, ensuring a smooth and successful deployment.'
            }
        },
        proj:{
            desc:'Description:',
            feat:'Features:',
            keyFeat:'Key Features:',
            title:'My Projects',
            1:{
                title:'Spinning Bike Web Application',
                desc:'A web application that allows bike owners to monitor their fleet and access detailed training data with\n' +
                    '            real-time updates. <br><br> Includes an admin account for managing users.',
                desc2:'A web application that allows bike owners to monitor their fleet and access detailed training data with\n' +
                    '          real-time updates. <br> Includes an admin account for managing clients.',
                bp1Title:'Robust and Scalable Infrastructure:',
                bp1Desc:'CI/CD with a scalable architecture, ensuring seamless updates and consistent performance as user and data loads increase.',
                bp2Title:'Secure Platform:',
                bp2Desc:'Ensures data security with HTTPS certificates and Dockerization, providing robust security measures.',
                bp3Title:'Admin Management and Support:',
                bp3Desc:'Tools for administrators to manage client data, bike fleets, gym details, mobile app info, and session records, including a dedicated support page for user assistance.',
                bp4Title:'User Dashboard:',
                bp4Desc:'A personalized dashboard providing users with real-time updates on spinning sessions, performance metrics, and progress tracking.',
                bp5Title:'Comprehensive Training Data and Analytics:',
                bp5Desc:'Detailed insights and analysis for gym and bike owners to optimize training and performance.',
                bp6Title:'Live Energy Monitoring:',
                bp6Desc:'A live display for gyms showing real-time updates on energy generation.',
            },
            2:{
                title:'AWS Infrastructure',
                desc:' A complex IT infrastructure on AWS, securely deploying all core services to ensure efficient,\n' +
                    '            interconnected, and scalable operations, while integrating various AWS services to support essential IT\n' +
                    '            needs of the business.',
                bp1Title:'Deploy IT Projects:',
                bp1Desc:'Flexible and secure infrastructure using EC2 to deploy backend, frontend, and database services, in a scalable and cost-efficient way.',
                bp2Title:'Comprehensive Data Management:',
                bp2Desc:'S3 (Simple Storage Service) for secure and reliable file storage of private and public files, and database backups, ensuring data integrity and accessibility.',
                bp3Title:'Advanced IoT Integration:',
                bp3Desc:'IoT Core for efficient IoT fleet management, enabling real-time data collection and communication between the spinning bikes and the backend system.',
                bp4Title:'Automated Deployment and Maintenance:',
                bp4Desc:'Lambda functions to automate various scripts, including sending workout data from IoT Core to the API, streamlining maintenance tasks and reducing manual intervention.',
                bp5Title:'Domain and Email Management:',
                bp5Desc:'Implemented Route 53 for domain management and SES for monitored email sending, with optimizations on reachability.',
            },
            3: {
                title:'Spinning Bike Backend',
                desc:'Developed a robust API backend for Off The Grid\'s smart spinning bike IoT project, ensuring data integrity and efficient communication with the mobile app, web app, and IoT Core Infrastructure, all deployed on AWS.',
                desc2:'<br><br>\n' +
                    '          The backend project for Off The Grid\'s smart spinning bike IoT platform serves as a critical API for the frontend, mobile app, and IoT Core Infrastructure. It stores bike spinning workout sessions and manages comprehensive data, including user data, challenges, training sessions, and IoT fleet information.\n' +
                    '          <br><br>\n' +
                    '          Initially developed in Laravel and later migrated to TypeScript, NodeJS, Express, and Sequelize, the backend communicates with a centralized database. It ensures robust data integrity and secure communication, supporting seamless real-time data updates and integration across all devices and applications.\n' +
                    '          <br>\n' +
                    '          <br>\n' +
                    '\n' +
                    '          Leveraging best practices, the backend provides a solid foundation for the entire platform\'s functionality.\n' +
                    '          <br> <br> <br>',
                bp1Title:'Centralized database for all user and workout data',
                bp2Title:'Secure communication with mobile app, web app, and IoT devices',
                bp3Title:'Real-time data updates and integration',
                bp4Title:'Deployed on AWS for scalability, reliability, and security',

            },
            4: {
                title:'Spinning Bike Mobile App',
                desc:'A mobile app for spinning bike users, offering a gamified experience and access to training data. <br> <br> Built with Kotlin Multiplatform, featuring Bluetooth connectivity, local database, and modern UI practices for performance and security.',
                desc2:'A mobile app for spinning bike users, offering a gamified experience and access to training data. <br> Built with Kotlin Multiplatform, featuring Bluetooth connectivity, local database, and modern UI practices for performance and security.',
                bp1Title:'Gamified User Experience:',
                bp1Desc:'Provides users with access to detailed training data, featuring challenges and achievement badges to motivate and enhance user engagement.',
                bp2Title:'Modern and Friendly User Interface:',
                bp2Desc:'Implements modern UI practices using Compose for Android and SwiftUI for iOS, offering a smooth, responsive, and user-friendly interface.',
                bp3Title:'Robust MVVM Architecture:',
                bp3Desc:'Employs a Model-View-ViewModel (MVVM) structure, ensuring a clear separation between data, domain, and presentation layers, optimized performance, and adherence to security best practices.',
                bp4Title:'Secure API and Local Storage:',
                bp4Desc:'Utilizes secure HTTPS connectivity for backend integration and a local SQLite database for offline data storage and retrieval.',
                bp5Title:'Seamless Bluetooth Connectivity:',
                bp5Desc:'Ensures easy and reliable Bluetooth connection to the IoT spinning bike via QR code scanning for session data synchronization.',
                bp6Title:'Scalable and Maintainable Design:',
                bp6Desc:'Scalability and easy maintenance, allowing for seamless updates and new feature integration, with an emphasis on code reusability and documentation.',

            },
            5: {
                title:'This Portfolio',
                desc: 'This project, developed using Vue.js and Vuetify, highlights my expertise in front-end development. It emphasizes responsive design and dynamic content, demonstrating my proficiency in creating visually appealing and functional web applications.'
            },
            6: {
                title:'Full Stack Blockbuster Clone Project',
                projectPhase:' Project Phases:',
                desc:'A web-based film rental and streaming application featuring an Oracle SQL database, Java backend, and JavaScript frontend, allowing users to rent films, view detailed metadata, and search content through an integrated video player.',
                num1Title:'Database Creation:',
                num1Desc1:'Developed and populated an Oracle SQL database using scripts and XML files.',
                num2Title:'User Interface Development',
                num2Desc1:'Built a web interface with React and JavaScript.',
                num2Desc2:'Developed a Java backend for system-database interactions.',
                num2Desc3:'Designed a user-friendly interface for film rental and searches.',
                num3Title:'Feature Enhancement',
                num3Desc1:'Added metadata extraction from video trailers.',
                num3Desc2:'Integrated a video player to view trailers and metadata.',
                num3Desc3:'Enabled content-based video searches.',
                bp1Title:'Metadata Extraction:',
                bp1Desc:'Algorithm to extract and display metadata such as duration, resolution, dominant color, and color space from video trailers.',
                bp2Title:'Content-Based Search:',
                bp2Desc:'User-friendly search interface for videos using extracted metadata.',
                bp3Title:'Integrated Video Player:',
                bp3Desc:'Video player within the application to view trailers and access metadata.',
                bp4Title:'Client-Server Architecture:',
                bp4Desc:'REST API for seamless communication between frontend and backend, ensuring maintainability and role separation.',

            }
        },
        contactPage:{
            desc1:'Currently available for a Full Stack, Backend, Frontend or Mobile Developer Roles in Montréal, On-Site, Hybrid or Remote.',
            desc2:'Feel free to contact me.'
        },

        clickToDownload: 'Click to Download',


    },
    fr: {
        home: 'Accueil',
        aboutMeTitle: 'A propos de moi',
        skills: 'Compétences',
        work: 'Expérience Professionnelle',
        projects: 'Projets',
        contact: 'Contact',
        mail: 'Courriel',
        myResume: 'Mon CV',
        clickToCopy: 'Clique pour copier',
        clickToDownload: 'Clique pour télécharger',
        usernameCopied: 'Nom d\'utilisateur copié dans le presse-papier',
        hi: 'Salut, ici Mathis',
        fsDev: 'Développeur Full Stack',
        backendDev: 'Développeur Backend',
        frontendDev: 'Développeur Frontend',
        mobDev: 'Développeur Mobile',
        description: {
            part1: 'Passionné par <span class="text-info font-weight-bold">la technologie</span> et <span class="text-secondary font-weight-bold">l\'écologie</span> 🌱, j\'ai travaillé en tant que CIO et développeur principal pendant presque 3 ans dans une startup.',
            part2: 'J’aimerais apporter mon expertise et mettre mes compétences au service d\'une entreprise dédiée à la protection de <span class="text-secondary font-weight-bold">l\'environnement</span> 🌍.'
        },
        aboutMe:{
            title: 'A propos de moi',
            description: 'Diplômé d\'un double master en informatique de <span class="text-info"\n' +
                '                                                               style="font-weight: bold">ETS Montréal</span> et <span\n' +
                '            class="text-info" style="font-weight: bold">Junia HEI</span> (école d\'ingénieur généraliste en France).\n' +
                '          <br>\n' +
                '          Je suis autodidacte et j\'excelle à la fois dans des environnements indépendants et en équipe.<br>\n' +
                '          Connu pour mon adaptabilité et ma polyvalence, j\'aime relever de nouveaux défis.',
            interests:'<strong class="text-secondary">Intérêts : </strong> Muay Thai, Guitare, Lecture, Jeux Vidéo',
            native: 'Maternelle',
            bilingual: 'Bilingue',
            conversational: 'Intermédiaire',
            fullStackExp: '<span class="number text-primary">5 Ans</span> <span class="label">d\'Expérience en Dev Full Stack</span>',
            mobileExp: '<span class="number text-primary">2 Ans</span> <span class="label">d\'Expérience en Dev Mobile</span>',
            projectsCompleted: '<span class="number text-primary">10+</span> <span class="label">Projets Réalisés</span>'
        },
        skillsPage: {
            title: 'Compétences',
            devOps: 'DevOps, Sevices Cloud & Version Control',
        },
        readMore:'Voir plus',
        readLess:'Voir moins',
        workPage: {
            title: 'Expérience Professionnelle',
            keyAchiev: 'Réalisations Clés:',
            otg: {
                title: 'Lead Developer chez Off The Grid',
                duration: 'Septembre 2021 - Juillet 2024 (~ 3 ans)',
                description: 'En tant que <strong class="text-blue-lighten-1">Lead Developer</strong> & <strong class="text-blue-lighten-1">Chief\n' +
                    '                Information Officer</strong> pendant près de 3 ans, j\'étais chargé de concevoir,\n' +
                    '                développer et maintenir toute l\'infrastructure informatique pour un vélo de spinning IoT intelligent.<br><br>\n' +
                    '                J\'ai mis à profit mes compétences en <strong class="text-blue-lighten-1">développement full stack</strong> et ma <strong\n' +
                    '                  class="text-blue-lighten-1">vaste expérience avec\n' +
                    '                AWS</strong> pour créer une infrastructure robuste et\n' +
                    '                interconnectée. J\'ai également développé une\n' +
                    '                <strong class="text-blue-lighten-1">application mobile</strong> permettant aux utilisateurs d\'accéder facilement à leurs données.<br><br>\n' +
                    '                J\'ai géré tous les autres besoins informatiques de la startup, y compris le développement IoT, la gestion de domaine, la cybersécurité et\n' +
                    '                la délivrabilité des emails.',
                bp1Title: 'Développement Back-End:',
                bp1Desc: 'Développement d\'une API sécurisée, robuste et évolutive avec NodeJS, ExpressJS et Sequelize, gérant les interactions de la base de données et desservant l\'application web, l\'application mobile et les services IoT pour le stockage des sessions d\'entraînement.',
                bp2Title: 'Développement Front-End:',
                bp2Desc: 'Conception d\'une application web permettant aux clients d\'accéder à leur flotte et de consulter des analyses de données sur les sessions d\'entraînement, l\'utilisation mensuelle et les progrès. Comprend une interface d\'administration pour la gestion des clients, la surveillance de la flotte et le suivi des tendances et problèmes des données.',
                bp3Title: 'Développement d\'Application Mobile:',
                bp3Desc: 'Développement d\'une application mobile gamifiée permettant aux utilisateurs de suivre leurs données d\'entraînement, améliorant l\'engagement et l\'expérience grâce à des défis écologiques.',
                bp4Title: 'Intégration AWS:',
                bp4Desc: 'Utilisation de connaissances approfondies d\'AWS pour construire un écosystème évolutif et fiable, en déployant chaque ressource avec des pratiques CI/CD.',
                conclusion: 'Mon travail chez Off The Grid met en évidence mes capacités en tant que développeur full-stack, en mettant l\'accent sur la conception efficace d\'infrastructure et l\'intégration transparente des composants technologiques.'
            },
            tessi: {
                title: "Automatisation des tests logiciels chez Tessi",
                duration: "Juin - Juillet 2019 (2 mois)",
                description: "Conception, préparation et exécution de la phase de test pour une mise à jour majeure d'une application, en utilisant SquashTM.\n" +
                    "Collaboration avec l'équipe de développement pour identifier et résoudre les problèmes, assurant un déploiement fluide et réussi."
            }
        },
        proj: {
            desc: 'Description:',
            feat: 'Fonctionnalités:',
            keyFeat:'Fonctionnalités Clés:',
            title:'Mes Projets',
            1: {
                title: 'Application Web pour Vélos Spinning',
                desc: 'Une application web permettant aux propriétaires de vélos de surveiller leur flotte et d\'accéder à des données d\'entraînement détaillées avec des mises à jour en temps réel. <br><br> Comprend un compte administrateur pour la gestion des utilisateurs.',
                desc2: 'Une application web permettant aux propriétaires de vélos de surveiller leur flotte et d\'accéder à des données d\'entraînement détaillées avec des mises à jour en temps réel. <br> Comprend un compte administrateur pour la gestion des clients.',
                bp1Title: 'Infrastructure Robuste et Scalable :',
                bp1Desc: 'CI/CD avec une architecture évolutive, garantissant des mises à jour transparentes et des performances constantes à mesure que les charges d\'utilisateurs et de données augmentent.',
                bp2Title: 'Plateforme Sécurisée :',
                bp2Desc: 'Assure la sécurité des données avec des certificats HTTPS et la Dockerisation, offrant des mesures de sécurité robustes.',
                bp3Title: 'Gestion et Support Administratif :',
                bp3Desc: 'Outils pour les administrateurs pour gérer les données des clients, les flottes de vélos, les détails des salles de sport, les informations de l\'application mobile et les enregistrements de session, incluant une page de support dédiée à l\'assistance des utilisateurs.',
                bp4Title: 'Tableau de Bord Utilisateur :',
                bp4Desc: 'Un tableau de bord personnalisé fournissant aux utilisateurs des mises à jour en temps réel sur les sessions de spinning, les métriques de performance et le suivi des progrès.',
                bp5Title: 'Analyse de Données d\'Entraînement :',
                bp5Desc: 'Informations détaillées et analyses pour les propriétaires de salles de sport et de vélos afin d\'optimiser l\'entraînement et la performance.',
                bp6Title: 'Live Display pour les gyms :',
                bp6Desc: 'Affichage en direct pour les salles de sport montrant des mises à jour en temps réel sur la génération d\'énergie.',
            },
            2: {
                title: 'Infrastructure AWS',
                desc: 'Une infrastructure IT complexe sur AWS, déployant de manière sécurisée tous les services essentiels pour assurer des opérations efficaces, interconnectées et évolutives, tout en intégrant divers services AWS pour répondre aux besoins IT essentiels de l\'entreprise.',
                bp1Title: 'Déploiement de Projets IT :',
                bp1Desc: 'Infrastructure flexible et sécurisée utilisant EC2 pour déployer les services backend, frontend et base de données, de manière évolutive et rentable.',
                bp2Title: 'Gestion des Données Complète :',
                bp2Desc: 'S3 (Simple Storage Service) pour le stockage sécurisé et fiable des fichiers privés et publics, ainsi que des sauvegardes de bases de données, garantissant l\'intégrité et l\'accessibilité des données.',
                bp3Title: 'Intégration IoT Avancée :',
                bp3Desc: 'IoT Core pour une gestion efficace de la flotte IoT, permettant la collecte de données en temps réel et la communication entre les vélos de spinning et le système backend.',
                bp4Title: 'Déploiement et Maintenance Automatisés :',
                bp4Desc: 'Fonctions Lambda pour automatiser divers scripts, y compris l\'envoi de données d\'entraînement de IoT Core à l\'API, rationalisant les tâches de maintenance et réduisant l\'intervention manuelle.',
                bp5Title: 'Gestion de Domaine et d\'Email :',
                bp5Desc: 'Implémentation de Route 53 pour la gestion de domaine et SES pour l\'envoi d\'emails surveillés, avec des optimisations sur la délivrabilité.',
            },
            3: {
                title: 'Backend pour Vélo Spinning',
                desc: 'Développement d\'une API backend robuste pour le projet IoT de vélo spinning intelligent de Off The Grid, assurant l\'intégrité des données et une communication efficace avec l\'application mobile, l\'application web et l\'infrastructure IoT Core, le tout déployé sur AWS.',
                desc2: '<br><br> Le projet backend pour la plateforme IoT de vélo spinning intelligent de Off The Grid sert d\'API critique pour le frontend, l\'application mobile et l\'infrastructure IoT Core. Il stocke les sessions d\'entraînement des vélos spinning et gère des données complètes, y compris les données des utilisateurs, les défis, les sessions d\'entraînement et les informations sur la flotte IoT. <br><br> Initialement développé en Laravel puis migré vers TypeScript, NodeJS, Express et Sequelize, le backend communique avec une base de données centralisée. Il assure une intégrité robuste des données et une communication sécurisée, supportant des mises à jour de données en temps réel et une intégration fluide entre tous les appareils et applications. <br><br> En s\'appuyant sur les meilleures pratiques, le backend fournit une base solide pour la fonctionnalité de toute la plateforme. <br> <br> <br>',
                bp1Title: 'Base de données centralisée pour toutes les données des utilisateurs et des entraînements',
                bp2Title: 'Communication sécurisée avec l\'application mobile, l\'application web et les appareils IoT',
                bp3Title: 'Mises à jour et intégration des données en temps réel',
                bp4Title: 'Déployé sur AWS pour l\'évolutivité, la fiabilité et la sécurité',
            },
            4: {
                title: 'Application Mobile pour Vélo Spinning',
                desc: 'Une application mobile pour les utilisateurs de vélos spinning, offrant une expérience gamifiée et l\'accès aux données d\'entraînement. <br> <br> Développée avec Kotlin Multiplatform, incluant la connectivité Bluetooth, une base de données locale et des pratiques UI modernes pour la performance et la sécurité.',
                desc2:'Une application mobile pour les utilisateurs de vélos spinning, offrant une expérience gamifiée et l\'accès aux données d\'entraînement. <br> Développée avec Kotlin Multiplatform, incluant la connectivité Bluetooth, une base de données locale et des pratiques UI modernes pour la performance et la sécurité.',
                bp1Title: 'Expérience Utilisateur gamifiée :',
                bp1Desc: 'Fournit aux utilisateurs un accès à des données d\'entraînement détaillées, avec des défis et des badges de réussite pour motiver et améliorer l\'engagement des utilisateurs.',
                bp2Title: 'Interface Utilisateur Moderne et Agréable :',
                bp2Desc: 'Implémente des pratiques UI modernes en utilisant Compose pour Android et SwiftUI pour iOS, offrant une interface fluide, réactive et conviviale.',
                bp3Title: 'Architecture MVVM Robuste :',
                bp3Desc: 'Emploie une structure Model-View-ViewModel (MVVM), assurant une séparation claire entre les données, le domaine et les couches de présentation, une performance optimisée et le respect des meilleures pratiques de sécurité.',
                bp4Title: 'API Sécurisée et Stockage Local :',
                bp4Desc: 'Utilise une connectivité HTTPS sécurisée pour l\'intégration backend et une base de données locale SQLite pour le stockage et la récupération de données hors ligne.',
                bp5Title: 'Connectivité Bluetooth Transparente :',
                bp5Desc: 'Assure une connexion Bluetooth facile et fiable au vélo spinning IoT via le scan de QR code pour la synchronisation des données de session.',
                bp6Title: 'Design Évolutif et Facile à Maintenir :',
                bp6Desc: 'Scalabilité et maintenance facile, permettant des mises à jour fluides et l\'intégration de nouvelles fonctionnalités, avec un accent sur la réutilisabilité du code et la documentation.',
            },
            5: {
                title: 'Ce Portfolio',
                desc: 'Ce projet, développé avec Vue.js et Vuetify, met en avant mon expertise en développement front-end. Il met l\'accent sur un design réactif et un contenu dynamique, démontrant ma compétence à créer des applications web visuellement attrayantes et fonctionnelles.',
            },
            6: {
                title: 'Projet Clone Blockbuster Full Stack',
                projectPhase:' Phases du projet:',
                desc: 'Une application web de location et de streaming de films avec une base de données Oracle SQL, un backend Java et un frontend JavaScript, permettant aux utilisateurs de louer des films, de visualiser des métadonnées détaillées et de rechercher du contenu via un lecteur vidéo intégré.',
                num1Title: 'Création de Base de Données :',
                num1Desc1: 'Développement et population d\'une base de données Oracle SQL en utilisant des scripts et des fichiers XML.',
                num2Title: 'Développement de l\'Interface Utilisateur :',
                num2Desc1: 'Création d\'une interface web avec React et JavaScript.',
                num2Desc2: 'Développement d\'un backend Java pour les interactions système-base de données.',
                num2Desc3: 'Conception d\'une interface conviviale pour la location et la recherche de films.',
                num3Title: 'Amélioration des Fonctionnalités :',
                num3Desc1: 'Ajout d\'une extraction de métadonnées à partir des bandes-annonces.',
                num3Desc2: 'Intégration d\'un lecteur vidéo pour visionner les bandes-annonces et accéder aux métadonnées.',
                num3Desc3: 'Activation de recherches vidéo basées sur le contenu.',
                bp1Title: 'Extraction de Métadonnées :',
                bp1Desc: 'Algorithme pour extraire et afficher des métadonnées telles que la durée, la résolution, la couleur dominante et l\'espace colorimétrique des bandes-annonces.',
                bp2Title: 'Recherche Basée sur le Contenu :',
                bp2Desc: 'Interface de recherche conviviale pour les vidéos en utilisant les métadonnées extraites.',
                bp3Title: 'Lecteur Vidéo Intégré :',
                bp3Desc: 'Lecteur vidéo intégré à l\'application pour visionner les bandes-annonces et accéder aux métadonnées.',
                bp4Title: 'Architecture Client-Serveur :',
                bp4Desc: 'API REST pour une communication fluide entre le frontend et le backend, garantissant la maintenabilité et la séparation des rôles.',
            },
        },
        contactPage: {
            desc1: 'Actuellement disponible pour des rôles de développeur Full Stack, Backend, Frontend ou Mobile à Montréal, en présentiel, hybride ou à distance.',
            desc2: 'N\'hésitez pas à me contacter.'
        },



    },
};

const i18n = createI18n({
    legacy: false,
    fallbackLocale: 'fr',
    locale: 'en',
    messages,
});

export default i18n;
