<template>
  <v-container class="ml-10 mr-10" fluid>
    <v-row align="start" justify="start">

      <v-col cols="12" class="text-center">
        <v-divider thickness="10" width="50%" color="primary" opacity="0.7" class="rounded mb-10 mx-md-0 mx-auto"></v-divider>

        <h1 class="text-primary">{{ $t('aboutMe.title') }}</h1>
      </v-col>
      <v-col cols="12" md="6" class="mb-10 mb-lg-0">
        <p class="mb-5 description"  v-html="$t('aboutMe.description')">
        </p>
        <p class="description mb-10 " v-html="$t('aboutMe.interests')"></p>
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <div class="info-container">
              <v-img src='@/assets/fr.svg' width="45%" class="mb-2"></v-img>
              <div><strong class="text-background">{{ $t('aboutMe.native') }}</strong></div>
            </div>
          </v-col>
          <v-col class="text-center">
            <div class="info-container">

              <v-img src='@/assets/us.png' width="45%" class="mb-2"></v-img>
              <div><strong class="text-background">{{ $t('aboutMe.bilingual') }}</strong></div>
            </div>
          </v-col>
          <v-col class="text-center">
            <div class="info-container">
              <v-img src='@/assets/germany.svg' width="45%" class="mb-2"></v-img>
              <div><strong class="text-background">{{ $t('aboutMe.conversational') }}</strong></div>
            </div>
          </v-col>
          <v-col class="text-center">
            <div class="info-container">
              <v-img src='@/assets/spain.svg' width="45%" class="mb-2"></v-img>
              <div><strong class="text-background">{{ $t('aboutMe.conversational') }}</strong></div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="center-items big-numbers-container">
        <div class="big-number">
          <span class="number text-primary">5 Years</span> <span class="label">Full Stack Experience</span>
        </div>
        <v-divider width="30%" color="primary" opacity="0.7" class="mt-10 mb-10"></v-divider>
        <div class="big-number">
          <span class="number text-primary">2 Years</span> <span class="label">Mobile Experience</span>
        </div>
        <v-divider width="30%" color="primary" opacity="0.7" class="mt-10 mb-10"></v-divider>
        <div class="big-number">
          <span class="number text-primary">10+</span> <span class="label">Completed Projects</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';


export default defineComponent({
  name: 'AboutMePage',
});
</script>

<style scoped>
h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.description {
  font-size: 1.4em;
  font-weight: normal;
}

strong {
  font-weight: bold;
}
.flag {
  font-size: 1.5em;
  display: block;
  margin-bottom: 10px;
}

.info-container {
  background-color: rgb(var(--v-theme-text-secondary), 0.8);
  border-radius: 10px;
  padding: 20px;
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgb(var(--v-theme-text-secondary), 0.5);
}

.info-container:hover {
  cursor: pointer;
  background-color: rgb(var(--v-theme-secondary));
  box-shadow: 0 4px 10px rgb(var(--v-theme-primary), 0.8);
  transform: scale(1.1);
  transition: transform 0.5s, background-color 1s, box-shadow 0.5s;

}


.center-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.big-number {
  display: flex;
  align-items: baseline;
}

.number {
  font-size: 2em;
  font-weight: bold;
  margin-right: 10px;
}

.label {
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 10px;
}
.big-numbers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}


.text-center {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

@media (max-width: 960px) {
  h1 {
    font-size: 7vw !important;
    margin: 0;

  }

  .text-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

}

@media (max-width: 600px) {

  h1 {
    font-size: 10vw !important;
  }

}



</style>