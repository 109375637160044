<template>
  <v-app>
    <v-app-bar app color="primary" dark fixed>
      <v-toolbar-title>Mathis Dufrasnes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mx-3" variant="text" @click="scrollToSection('home')">{{ $t('home') }}</v-btn>

      <v-btn class="mx-3 d-none d-lg-flex" variant="text" @click="scrollToSection('aboutMe')">{{
          $t('aboutMeTitle')
        }}
      </v-btn>
      <v-btn class="mx-3 d-none d-lg-flex" variant="text" @click="scrollToSection('skills')">{{ $t('skills') }}</v-btn>
      <v-btn class="mx-3 d-none d-lg-flex" variant="text" @click="scrollToSection('work')">{{ $t('work') }}</v-btn>
      <v-btn class="mx-3 d-none d-lg-flex" variant="text" @click="scrollToSection('projects')">{{
          $t('projects')
        }}
      </v-btn>
      <v-btn class="mx-3 d-none d-lg-flex" variant="text" @click="scrollToSection('contact')">{{
          $t('contact')
        }}
      </v-btn>

      <v-btn class="mx-3" icon @click="toggleTheme">
        <v-icon>{{ isDark ? 'fa-solid fa-sun' : 'fa-solid fa-moon' }}</v-icon>
      </v-btn>
      <v-btn class="ml-3 mr-5" icon @click="toggleLanguage">
        <v-icon>
          <v-img :src="currentLanguage === 'en' ? require('@/assets/fr.svg') : require('@/assets/us.png')"></v-img>
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="isLargeScreen" app class="elevation-0 transparent d-none d-lg-flex" permanent width="60">
      <v-row align="end" class="fill-height pb-0 ma-0" justify="center">
        <v-col class="d-flex flex-column align-center  pb-0 pr-6 ma-0" cols="12">
          <a :href="resumeLink" target="_blank" class="mb-5 w-100">
            <v-img :src="isDark ? require('@/assets/resumeDark.svg') : require('@/assets/resume.svg')"
                   height="auto" width="auto"></v-img>
          </a>
          <a class="mb-5" href="mailto:mathis@dufrasnes.fr" target="_blank">
            <v-icon color="primary" icon="fas fa-envelope"></v-icon>
          </a>

          <a class="mb-5" href="https://www.linkedin.com/in/mathis-dufrasnes/" target="_blank">
            <v-icon color="primary" icon="fab fa-linkedin"></v-icon>
          </a>

          <a class="mb-5" href="https://github.com/mat-duf" target="_blank">
            <v-icon color="primary" icon="fab fa-github"></v-icon>
          </a>

          <v-icon class="mb-5" color="primary" icon="fab fa-discord" @click="copyDiscordToClipboard"></v-icon>
          <v-snackbar v-model="snackbar" :timeout="2000" color="success">
            {{ snackbarText }}
          </v-snackbar>
          <div class="vertical-bar"></div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-main>
      <v-container class="pa-0" fluid>
        <v-row id="home" class="section-spacing" justify="center">
          <v-col cols="12">
            <HomePage/>
          </v-col>
        </v-row>
        <v-row
            id="aboutMe" class="pl-lg-14 pl-4 pr-4 justify-custom section-spacing">
          <v-col cols="12" lg="10">
            <AboutMePage/>
          </v-col>
        </v-row>
        <v-row id="skills" class="pl-4 pr-4 section-spacing" justify="center">
          <v-col cols="12" md="10">
            <SkillsPage/>
          </v-col>
        </v-row>

        <v-row id="work" class="pl-lg-14 pl-4 pr-4 justify-custom section-spacing" justify="start">
          <v-col cols="12" md="10">
            <WorkExperiencePage/>
          </v-col>
        </v-row>
        <v-row id="projects" class="pl-4 pr-4" justify="center">
          <v-col cols="12" md="10">
            <ProjectsPage/>
          </v-col>
        </v-row>
        <div id="contact">
          <ContactPage/>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref} from 'vue';
import HomePage from '@/components/HomePage.vue';
import {useDisplay, useTheme} from 'vuetify';
import {useI18n} from "vue-i18n";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import AboutMePage from "@/components/AboutMePage.vue";
import SkillsPage from "@/components/SkillsPage.vue";
import WorkExperiencePage from "@/components/WorkExperiencePage.vue";
import ProjectsPage from "@/components/ProjectsPage.vue";
import ContactPage from "@/components/ContactPage.vue";

export default defineComponent({
  name: 'App',

  components: {
    ContactPage,
    ProjectsPage,
    WorkExperiencePage,
    SkillsPage,
    AboutMePage,
    HomePage,
  },

  setup() {
    const theme = useTheme();
    const {locale} = useI18n();
    const {lgAndUp} = useDisplay();

    const isDark = computed(() => theme.global.current.value.dark);
    const currentTheme = computed(() => theme.global.name.value);
    const currentLanguage = ref('en');

    const resumeLink = computed(() => {
      return currentLanguage.value === 'en' ? '/CV_EN.pdf' : '/CV_FR.pdf';
    });

    const setTheme = (themeName: string) => {
      theme.global.name.value = themeName;
      localStorage.setItem('preferredTheme', themeName);
    };

    const toggleTheme = () => {
      const newTheme = isDark.value ? 'lightTheme' : 'darkTheme';
      setTheme(newTheme);
    };

    const getPreferredTheme = () => {
      const storedTheme = localStorage.getItem('preferredTheme');
      if (storedTheme) {
        return storedTheme;
      }
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return prefersDark ? 'darkTheme' : 'lightTheme';
    };

    const setLanguage = (language: string) => {
      locale.value = language;
      currentLanguage.value = language;
      localStorage.setItem('preferredLanguage', language);
      console.log('Language set to:', language);
    };

    const toggleLanguage = () => {
      const newLanguage = currentLanguage.value === 'en' ? 'fr' : 'en';
      setLanguage(newLanguage);
    };

    const getPreferredLanguage = () => {
      const storedLanguage = localStorage.getItem('preferredLanguage');
      if (storedLanguage) {
        return storedLanguage;
      }
      return 'en';
    };

    const scrollToSection = (sectionId: string) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }


    onMounted(() => {
      const preferredTheme = getPreferredTheme();
      setTheme(preferredTheme);

      const preferredLanguage = getPreferredLanguage();
      setLanguage(preferredLanguage);
    });

    const icons = ['fas fa-envelope', 'fab fa-linkedin', 'fab fa-github', 'fab fa-discord']

    return {
      isDark,
      currentTheme,
      toggleTheme,
      currentLanguage,
      toggleLanguage,
      scrollToSection,
      icons,
      lgAndUp,
      resumeLink,
    };
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
    };
  },
  methods: {
    copyDiscordToClipboard() {
      const discordID = 'mathisduf';
      navigator.clipboard.writeText(discordID).then(() => {
        this.snackbarText = this.$t('usernameCopied');
        this.snackbar = true;
      });
    },
  },

  computed: {
    isLargeScreen() {
      return this.lgAndUp;
    },
  },

});
</script>

<style lang="scss" scoped>
.v-container {
  padding-top: 100px;
}


.section-spacing {
  margin-bottom: 100px;
}


.transparent {
  background-color: transparent !important;
  border: none !important;
}

.vertical-bar {
  bottom: 0;
  height: 100px;
  width: 4px;
  border-radius: 10px;
  background-color: rgb(var(--v-theme-primary), 1);
}

.v-row, .v-container, .v-column {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.justify-custom {
  justify-content: flex-start !important;
}

@media (max-width: 960px) {
  .justify-custom {
    justify-content: center !important;
  }
}


</style>
