<template>
  <v-container class="bg-card" fluid>
    <v-row align="center" class="text-center" justify="center">


      <v-col class="mb-5" cols="12">
        <h1 class="text-primary mb-3">{{ $t('contact') }}</h1>
        <div class="d-flex justify-center mb-10">
          <div style="width: 3%;">
            <v-divider class="rounded" color="primary" opacity="0.7" thickness="10"></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center mb-10">
          <h2 style="width: 50%">{{ $t('contactPage.desc1') }} <span class=" text-green">{{ $t('contactPage.desc2') }}</span></h2></div>
      </v-col>


      <v-row class="d-flex w-100 align-items-stretch justify-center" no-gutters>
        <v-col class="mb-5 d-flex justify-center" cols="12" lg="5" md="5">
          <v-card class="glow-button rounded-lg d-flex flex-column" outlined style="width: 90%;">
            <v-row align="center" class="h-100 pa-0 ma-0">
              <v-col class="h-100 d-flex align-center justify-center" cols="3"
                     style="border-right: 2px solid rgb(var(--v-theme-text), 0.4);">
                <v-icon size="x-large"><i class="fas fa-phone"></i></v-icon>
              </v-col>
              <v-col class="pa-0 d-flex flex-column" cols="9">
                <v-row class="flex-grow-1" no-gutters>
                  <v-col class="d-flex align-center justify-center py-10" cols="12"
                         style="border-bottom: 2px solid rgb(var(--v-theme-text), 0.4);">
                    <img :src="require('@/assets/canada.svg')" class="mr-10" style="height: 30px; max-height: 30px; min-height: 30px"/>
                    <h2 class="mb-0 pa-0">+1 438-228-8766</h2>
                  </v-col>
                  <v-col class="d-flex align-center justify-center py-10" cols="12">
                    <img :src="require('@/assets/fr.svg')" class="mr-10" style="height: 30px; max-height: 30px; min-height: 30px"/>
                    <h2 class="mb-0 pa-0">+33 6 95 17 21 37</h2>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col class="mb-5 d-flex justify-center" cols="12" lg="5" md="5">
          <v-card class="rounded-lg d-flex flex-column glow-button" outlined style="width: 90%;" @click="openEmail">
            <v-row align="center" class="h-100 pa-0 ma-0">
              <v-col class="h-100 d-flex align-center justify-center" cols="3"
                     style="border-right: 2px solid rgb(var(--v-theme-text), 0.4);">
                <v-icon size="x-large"><i class="fas fa-envelope"></i></v-icon>
              </v-col>
              <v-col class="pa-0 d-flex flex-column" cols="9">
                <v-row class="flex-grow-1" no-gutters>
                  <v-col class="d-flex align-center justify-center py-5" cols="12">
                    <h2 class="mb-0 pa-0">mathis@dufrasnes.fr</h2>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-row class="d-flex justify-center align-center mb-10" style="margin-top: 100px">
            <v-row align="center" class="social-icons" justify="center">
              <v-col class="text-center" cols="auto">
                <a class="d-flex flex-column align-items-center" href="mailto:mathis@dufrasnes.fr" target="_blank">
                  <v-icon class="mb-2" color="primary" icon="fas fa-envelope"></v-icon>
                  <v-tooltip activator="parent" location="bottom">{{ $t('mail') }}</v-tooltip>
                </a>
              </v-col>
              <v-col class="text-center" cols="auto">
                <a class="d-flex flex-column align-items-center" href="https://www.linkedin.com/in/mathis-dufrasnes/"
                   target="_blank">
                  <v-icon class="mb-2" color="primary" icon="fab fa-linkedin"></v-icon>
                  <v-tooltip activator="parent" location="bottom">LinkedIn</v-tooltip>
                </a>
              </v-col>
              <v-col class="text-center" cols="auto">
                <a class="d-flex flex-column align-items-center" href="https://github.com/mat-duf" target="_blank">
                  <v-icon class="mb-2" color="primary" icon="fab fa-github"></v-icon>
                  <v-tooltip activator="parent" location="bottom">GitHub</v-tooltip>
                </a>
              </v-col>
              <v-col class="text-center" cols="auto">
                <div class="d-flex flex-column align-items-center">
                  <v-icon class="mb-2" color="primary" icon="fab fa-discord" @click="copyDiscordToClipboard"></v-icon>
                  <v-tooltip activator="parent" location="bottom">Discord<br> {{ $t('clickToCopy') }}</v-tooltip>
                </div>
                <v-snackbar v-model="snackbar" :timeout="2000" color="success">
                  {{ snackbarText }}
                </v-snackbar>
              </v-col>
              <v-col class="text-center" cols="auto">
                <a :href="resumeLink" target="_blank" class="pb-2">
                  <v-img :src="isDark ? require('@/assets/resumeDark.svg') : require('@/assets/resume.svg')"
                         height="24px" width="24px"></v-img>
                  <v-tooltip activator="parent" location="bottom">{{ $t('myResume') }} <br> {{ $t('clickToDownload') }}</v-tooltip>
                </a>
              </v-col>

            </v-row>
            <v-col class="text-center-small mt-4" cols="12">
              <div>© 2024 Mathis Dufrasnes</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";
import TypeIt from "typeit";
import {useDisplay, useTheme} from "vuetify";


export default defineComponent({
  name: 'ContactPage',

  data() {
    return {
      snackbar: false,
      snackbarText: '',
    };
  },
  setup() {
    const theme = useTheme();

    const isDark = computed(() => theme.global.current.value.dark);

    const {locale} = useI18n();
    const currentLanguage = computed(() => locale.value);

    const resumeLink = computed(() => {
      return currentLanguage.value === 'en' ? '/CV_EN.pdf' : '/CV_FR.pdf';
    });

    return {
      currentLanguage,
      resumeLink,
      isDark,
    };
  },
  methods: {
    openEmail() {
      window.open('mailto:mathis@dufrasnes.fr', '_blank');
    },
    openLinkedIn() {
      window.open('https://linkedin.com/in/mathis-dufrasnes', '_blank');
    },
    openGitHub() {
      window.open('https://github.com/mat-duf', '_blank');
    },
    openDiscord() {
      window.open('https://discord.com', '_blank');
    },
    copyDiscordToClipboard() {
      const discordID = 'mathisduf';
      navigator.clipboard.writeText(discordID).then(() => {
        this.snackbarText = this.$t('usernameCopied');
        this.snackbar = true;
      });
    },
  },

});
</script>

<style scoped>
h1 {
  font-size: 3em;
  margin-bottom: 20px;
}


strong {
  font-weight: bold;
}

p {
  margin-bottom: 45px;
  line-height: 1.8;
}


@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px rgb(var(--v-theme-primary-darken-1), 0.2), 0 0 15px rgb(var(--v-theme-primary-darken-1), 0.2), 0 0 25px rgb(var(--v-theme-primary-darken-1), 0.2), 0 0 35px rgb(var(--v-theme-primary-darken-1), 0.2);
  }
  50% {
    box-shadow: 0 0 15px rgb(var(--v-theme-primary), 0.6), 0 0 20px rgb(var(--v-theme-primary), 0.6), 0 0 30px rgb(var(--v-theme-primary), 0.6), 0 0 40px rgb(var(--v-theme-primary), 0.6);
  }
}

.glow-button {
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  animation: glow 3s ease-out infinite;
  transition: background 0.3s;
}

.glow-button:hover {
  background: rgb(var(--v-theme-secondary-darken-5), 0.9);
  animation-play-state: paused;
}

.v-row, .v-container, .v-column {
  margin-left: 0;
  margin-right: 0;
}

.text-center-small {
  text-align: center;
}

.social-icons a {
  text-decoration: none;
}

.social-icons v-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons v-icon {
  font-size: 2rem;
}

.social-icons v-tooltip {
  margin-top: 0.5rem;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

@media (max-width: 960px) {
  h1 {
    font-size: 7vw !important;
    margin: 0;

  }

  .text-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

}

@media (max-width: 600px) {

  h1 {
    font-size: 10vw !important;
  }

}

</style>